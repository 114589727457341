<nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a class="navbar-brand brand-logo" routerLink="/"><img  src="assets/images/dashboard/logeEuro.jpg" alt="logo" /></a>
        <!-- <a class="navbar-brand brand-logo-mini" routerLink="/"><img src="assets/images/logo-mini.svg" alt="logo" /></a> -->
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-stretch">
        <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" (click)="toggleSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
        <!-- <div class="search-field d-none d-md-block">
            <form class="d-flex align-items-center h-100" action="#">
                <div class="input-group">
                    <div class="input-group-prepend bg-transparent">
                        <i class="input-group-text border-0 mdi mdi-magnify"></i>
                    </div>
                    <input type="text" class="form-control bg-transparent border-0" placeholder="Search projects">
                </div>
            </form>
        </div> -->
        <ul class="navbar-nav navbar-nav-right">
            <li class="nav-item nav-profile dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle" id="profileDropdown" ngbDropdownToggle>
                    <div class="nav-profile-img">
                        <img src="assets/images/Profile_avatar_placeholder_large.png" alt="image">
                        <span class="availability-status online"></span>
                    </div>
                    <div class="nav-profile-text">
                        <p class="mb-1 text-black" translate>{{profileData?.name}}</p>
                    </div>
                </a>
                <div class="dropdown-menu navbar-dropdown" ngbDropdownMenu aria-labelledby="profileDropdown">
                    <a class="dropdown-item" [routerLink]="'/profile'" translate>
                        <i class="mdi mdi-cached mr-2 text-success"></i>{{'PAGE.PROFILE_DATA.PROFILE' |translate}}
                    </a>
                    <a class="dropdown-item" [routerLink]="'/authentication/editpassword'" translate>
                        <i class="mdi mdi-cached mr-2 text-success"></i>{{'PAGE.PROFILE_DATA.CHANE_PASSWORD' |translate}}
                    </a>
                    <!-- <div class="dropdown-divider"></div>
                    <a class="dropdown-item"  translate>
                        <i class="mdi mdi-logout mr-2 text-primary"></i> Signout
                    </a> -->
                </div>
            </li>
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link count-indicator dropdown-toggle" id="messageDropdown" ngbDropdownToggle>
                    <i class="mdi mdi-email-outline"></i>
                    <span class="count-symbol bg-warning"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" ngbDropdownMenu aria-labelledby="messageDropdown">
                    <h6 class="p-3 mb-0" translate>Messages</h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <img src="assets/images/faces/face4.jpg" alt="image" class="profile-pic">
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject ellipsis mb-1 font-weight-normal" translate>Mark send you a message</h6>
                            <p class="text-gray mb-0" translate>1 Minutes ago</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <img src="assets/images/faces/face2.jpg" alt="image" class="profile-pic">
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject ellipsis mb-1 font-weight-normal" translate>Cregh send you a message</h6>
                            <p class="text-gray mb-0" translate>15 Minutes ago</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <img src="assets/images/faces/face3.jpg" alt="image" class="profile-pic">
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject ellipsis mb-1 font-weight-normal" translate>Profile picture updated</h6>
                            <p class="text-gray mb-0" translate>18 Minutes ago</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <h6 class="p-3 mb-0 text-center" translate>4 new messages</h6>
                </div>
            </li>
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" ngbDropdownToggle>
                    <i class="mdi mdi-bell-outline"></i>
                    <span class="count-symbol bg-danger"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" ngbDropdownMenu aria-labelledby="notificationDropdown">
                    <h6 class="p-3 mb-0" translate>Notifications</h6>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <div class="preview-icon bg-success">
                                <i class="mdi mdi-calendar"></i>
                            </div>
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject font-weight-normal mb-1" translate>Event today</h6>
                            <p class="text-gray ellipsis mb-0" translate>Just a reminder that you have an event today</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <div class="preview-icon bg-warning">
                                <i class="mdi mdi-cog"></i>
                            </div>
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject font-weight-normal mb-1" translate>Settings</h6>
                            <p class="text-gray ellipsis mb-0" translate>Update dashboard</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item preview-item">
                        <div class="preview-thumbnail">
                            <div class="preview-icon bg-info">
                                <i class="mdi mdi-link-variant"></i>
                            </div>
                        </div>
                        <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 class="preview-subject font-weight-normal mb-1" translate>Launch Admin</h6>
                            <p class="text-gray ellipsis mb-0" translate>New admin wow!</p>
                        </div>
                    </a>
                    <div class="dropdown-divider"></div>
                    <h6 class="p-3 mb-0 text-center" translate> See all notifications</h6>
                </div>
            </li>
            <li class="nav-item nav-logout d-none d-lg-block">
                <a class="nav-link">
                    <i (click)="logout()" class="mdi mdi-power"></i>
                </a>
            </li>
           
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" (click)="toggleOffcanvas()">
      <span class="mdi mdi-menu"></span>
    </button>
    </div>
</nav>